/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../stylesheets/application.scss'
import slickSlider from '../vendor/slick/slider.js'

document.addEventListener('DOMContentLoaded', () => {
  slickSlider();
  (async function() {
    if (document.querySelector('#calendar')) {
      await import('../vendor/fullcalendar/fullcalendar.js').then((fullcalendar) => {
        fullcalendar.default();
      });
    }
    if (document.querySelector('.logged-in')) {
      await import('../src/main.js').then((vue) => { vue.mainComponents(); });
      await import('../src/ujs.js').then((remote) => { remote.default(); });
      await import('../src/blocks.js').then((blocks) => { blocks.initBlocks(); });
      await import('../src/events.js').then((event) => {event.default(); });
      await import('../src/page.js').then((page) => { page.default(); });
      await import('../src/sermons.js').then((sermon) => { sermon.default(); });
      await import('../src/livestream.js').then((livestream) => {
        livestream.default();
      });
      if (document.querySelector("[class*=tinymce]")) {
        await import('../vendor/tinymce/tinymce.js').then((module) => {
          module.initTinyMce();
        });
      }
      await import('../vendor/datetimepicker/datetimepicker.js').then((picker) => {
        picker.default();
      })
      await import('../src/tags.js').then((tags) => { tags.default() });
      await import('../src/sortable.js').then((sortable) => {
        sortable.imageSortable();
        sortable.initBlockSortable();
      });
      await import('../vendor/tooltip/tooltip.js').then((tips) => { tips.initTooltips() });
    }
  })();
})